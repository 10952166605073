<template>
  <div class="login-form">
      <div class="welcome-text">
        Welcome back! Please login to your account.
      </div>
      <form name="form" @submit.prevent="handleLogin">
        <div class="underline-input">
<!--          <label for="email">Email</label>-->
          <input
            v-model="user.username"
            v-validate="'required'"
            type="text"
            placeholder="Username"
            class="form-control"
            name="email"
          />
        </div>
        <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
        >
          Email is required!
        </div>
        <div class="underline-input">
<!--          <label for="password">Password</label>-->
          <input
            v-model="user.password"
            v-validate="'required'"
            type="password"
            :placeholder="passwordExpired ? 'Old Password' : 'Password'"
            class="form-control"
            name="password"
          />
        </div>
        <div
            v-if="errors.has('password')"
            class="alert alert-danger"
            role="alert"
        >
          Password is required!
        </div>
        <h6 v-if="passwordExpired">Password Expire. Enter New Password:</h6>
        <div class="underline-input" v-if="passwordExpired">
          <!--          <label for="password">Password</label>-->
          <input
              v-model="newPassword"
              v-validate="'required'"
              type="password"
              placeholder="New Password"
              class="form-control"
              name="new-password"
              ref="newPassword"
          />
        </div>
        <div
            v-if="errors.has('new-password')"
            class="alert alert-danger"
            role="alert"
        >
          New Password is required!
        </div>
        <div class="underline-input" v-if="passwordExpired">
          <!--          <label for="password">Password</label>-->
          <input
              v-model="confirmPassword"
              v-validate="'required|confirmed:newPassword'"
              type="password"
              placeholder="Re-Type Password"
              class="form-control"
              name="confirm-password"
          />
        </div>
        <div
            v-if="errors.has('confirm-password')"
            class="alert alert-danger"
            role="alert"
        >
          Please retype your new password.
        </div>
        <div class="flex-row-space-between">
          <b-form-checkbox>Remember me</b-form-checkbox>
          <router-link to="forgotten-password">Forgotten Password</router-link>
        </div>
        <Loading :is_loading="loading" class="loading" />
        <div class="flex-row-centered">
          <button class="rebutton" :disabled="loading">
<!--            <span-->
<!--              v-show="loading"-->
<!--              class="spinner-border spinner-border-sm"-->
<!--            ></span>-->
            <span v-if="passwordExpired">Change Password</span>
            <span v-else>Login</span>
          </button>
<!--          <button class="rebutton-outline" :disabled="loading">-->
<!--            <span-->
<!--                v-show="loading"-->
<!--                class="spinner-border spinner-border-sm"-->
<!--            ></span>-->
<!--            <span>Sign Up</span>-->
<!--          </button>-->
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </form>
    <div class="footer">
      <router-link to="/terms">Terms of Use</router-link>
      |
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
import User from '../models/user';
import Loading from "./Loading";

export default {
  name: 'Login',
  components: {
    Loading
  },
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
      passwordExpired: false,
      newPassword: null,
      confirmPassword: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/home');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', {user: this.user, newPassword: this.newPassword}).then(
            () => {
              this.$router.push('/home');
            },
            (error) => {
              this.loading = false;
              // This probably should be a 403 from the API.
              if (Object.hasOwn(error.response.data, 'error_code')) {
                if (error.response.data.error_code === 101) {
                  this.message = 'Your Password has EXPIRED. Change your password to continue.'
                  this.passwordExpired = true
                } else if ([100, 103].includes(error.response.data.error_code)) {
                  this.message = error.response.data.message
                }
              } else {
                this.message = 'There was an error logging you in: ' + error.response
              }
              console.log(error)
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%
}

.flex-row-space-between {
  margin-top: 4em;
}

.flex-row-centered {
  margin-top: 2em;
}

.welcome-text {
  text-align: center;
  font-weight: bold;
  color: #026cc0;
  margin-bottom: 10%;
}

label {
  display: block;
  margin-top: 10px;
}

.footer {
  display: block;
  position: fixed;
  left: 75%;
  transform: translate(-50%, 0);
  bottom: 7.5%;
  text-align: center;
  font-size: 0.75em;
}

.loading {
  font-size: 1em;
}

</style>
